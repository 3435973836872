<template>
  <v-layout row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="headline">Adauga {{ companyOnly ? 'companie' : 'punct de lucru' }}</span>
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" v-if="workingPointType == 'WORKING_POINT'">
              Trebuie sa adaugati detaliile punctului de lucru unde va desfasurati activitatea.
            </v-col>
            <v-col cols="12">
              <v-select v-if="!companyOnly" label="Tip punct de lucru *" v-model="workingPointType"
                :items="workingPointTypes" @change="changeWorkingPointType()" />
            </v-col>
          </v-row>
          <v-form class="w-full" ref="form">
            <v-row dense>
              <v-col cols="12">
                <v-autocomplete label="Alege organizatia *" v-model="model.company"
                  v-if="workingPointType == `WORKING_POINT`" :items="companies" color="blue-grey-lighten-2"
                  item-text="companyName" item-value="_id" :rules="[v => !!v || `Alege organizatia`]" item 
                  return-object>
                </v-autocomplete>
              </v-col>
              <!-- <v-col cols="12" md="4" v-if="workingPointType == 'COMPANY'">
                <v-text-field type="text" :placeholder="getCUILabel()" v-model="model.vatId" required
                  :label="getCUILabel()" :rules="getCUIRUles()" @blur="getPartnerDataFromAnaf" :disabled="waitForAnaf">
                </v-text-field>
              </v-col> -->
              <v-col cols="12" md="4" :class="{'order-1': workingPointType == 'COMPANY'}">
              <!-- <div class="padded w-full m-2"> -->
                <v-text-field ref='focusMe' type="text" :placeholder="getFieldLabel('name')" v-model="model.name" required
                  :label="getFieldLabel('name')" :loading="waitForAnaf" :disabled="waitForAnaf" :rules="[
                    v => !!v || `Completeaza numele`,
                    v => v && v.length <= 50 || `Numele trebuie sa contina cel mult 50 de caractere`
                  ]"  >
                  <template v-slot:append-outer v-if="workingPointType == 'WORKING_POINT'">
                    <v-tooltip
                      bottom max-width="250px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small>
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      Trebuie sa completati numele punctului de lucru la care va desfasurati activitatea. <br />
                      Acesta nu este unul legal, poate fi Magazin, Birou, numele magazinului, numele restaurantului, 
                      numele companiei daca aveti un singur punct de lucru etc
                    </v-tooltip>
                  </template>
                </v-text-field>
              <!-- </div> -->
              </v-col>
              
              <v-col cols="12" md="4" :class="{'order-3': workingPointType == 'COMPANY'}">
              <!-- <div class="padded w-full m-2"> -->
                <v-text-field type="text" :placeholder="getFieldLabel('city')" v-model="model.city" required :label="getFieldLabel('city')" 
                  :loading="waitForAnaf" :disabled="waitForAnaf"
                  :rules="[
                    v => !!v || `Completeaza orasul`,
                    v => v && v.length <= 100 || `Orasul trebuie sa contina cel mult 50 de caractere`
                  ]"  >
                  <template v-slot:append-outer v-if="workingPointType == 'WORKING_POINT'">
                    <v-tooltip
                      bottom max-width="250px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small>
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      Trebuie sa completati orasul in care este situat punctul de lucru asa cum este inregistrat la Registrul Comertului
                    </v-tooltip>
                  </template>
                </v-text-field>
              <!-- </div> -->
              </v-col>
              <v-col cols="12" md="4" :class="{'order-4': workingPointType == 'COMPANY'}">
              <!-- <div class="padded w-full m-2"> -->
                <v-text-field type="text" :placeholder="getFieldLabel('county')" v-model="model.county" required :label="getFieldLabel('county')" 
                :loading="waitForAnaf" :disabled="waitForAnaf"
                :rules="[
                  v => !!v || `Completeaza judetul`,
                  v => v && v.length <= 100 || `Judetul trebuie sa contina cel mult 50 de caractere`
                ]"  >
                  <template v-slot:append-outer v-if="workingPointType == 'WORKING_POINT'">
                    <v-tooltip
                      bottom max-width="250px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small>
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      Trebuie sa completati judetul in care este situat punctul de lucru asa cum este inregistrat la Registrul Comertului
                    </v-tooltip>
                  </template>
                </v-text-field>
              <!-- </div> -->
              </v-col>
              <v-col cols="12" :md="workingPointType == 'WORKING_POINT' ? '12' : '4'" :class="{'order-5': workingPointType == 'COMPANY'}">
              <!-- <div class="padded w-full m-2"> -->
                <v-text-field type="text" :placeholder="getFieldLabel('address')" v-model="model.address" required :label="getFieldLabel('address')"
                  :loading="waitForAnaf" :disabled="waitForAnaf"
                  :rules="[
                    v => !!v || `Completeaza adresa`,
                    v => v && v.length <= 100 || `Adresa trebuie sa contina cel mult 50 de caractere`
                  ]"  >
                  <template v-slot:append-outer v-if="workingPointType == 'WORKING_POINT'">
                    <v-tooltip
                      bottom max-width="250px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small>
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      Trebuie sa completati adresa la care este situat punctul de lucru asa cum este inregistrat la Registrul Comertului
                    </v-tooltip>
                  </template>
                </v-text-field>
              <!-- </div> -->
              </v-col>
              <v-col cols="12" md="4" :class="{'order-0': workingPointType == 'COMPANY'}">
              <!-- <div class="padded w-full m-2"> -->
                <v-text-field type="text" :placeholder="getCUILabel()" v-model="model.vatId" required
                  :label="getCUILabel()" :rules="getCUIRUles()" @blur="workingPointType == 'COMPANY' ? getPartnerDataFromAnaf() : null" 
                  :disabled="waitForAnaf" >
                  <template v-slot:append-outer v-if="workingPointType == 'WORKING_POINT'">
                    <v-tooltip
                      bottom max-width="250px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small>
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      Se completeaza numai daca punctul de lucru are Cod fiscal diferit de cel al companiei.
                    </v-tooltip>
                  </template>
                </v-text-field>
              <!-- </div> -->
              </v-col>
              <v-col cols="12" md="4" :class="{'order-2': workingPointType == 'COMPANY'}">
              <!-- <div class="padded w-full m-2"> -->
                <v-text-field type="text" placeholder="Nr Registrul comertului" 
                  v-model="model.regCom" label="Nr Registrul Comertului" :loading="waitForAnaf" :disabled="waitForAnaf"
                >
                  <template v-slot:append-outer v-if="workingPointType == 'WORKING_POINT'">
                    <v-tooltip
                      bottom max-width="250px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small>
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      Se completeaza numai daca punctul de lucru are Numar Registrul Comertului diferit de cel al companie.
                    </v-tooltip>
                  </template>
                </v-text-field>
              <!-- </div> -->
              </v-col>
              <v-col cols="12" :md="workingPointType == 'WORKING_POINT' ? '4' : '12'" :class="{'order-6': workingPointType == 'COMPANY'}">
              <!-- <div class="padded w-full m-2"> -->
                <v-autocomplete label="Alege tipul de activitate" v-model="model.activityType" :items="activityTypes"
                  color="blue-grey-lighten-2" >
                  <template v-slot:append-outer v-if="workingPointType == 'WORKING_POINT'">
                    <v-tooltip
                      bottom max-width="250px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small>
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      Alegeti tipul de activitate ce este desfasurata la punctul de lucru
                    </v-tooltip>
                  </template>
                </v-autocomplete>
              <!-- </div> -->
              </v-col>
              <v-col cols="12" md="4" v-if="workingPointType == 'WORKING_POINT'">
                <v-text-field type="text" placeholder="Persoana de contact" v-model="model.contactPerson" label="Persoana de contact" ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" v-if="workingPointType == 'WORKING_POINT'">
                <v-text-field type="text" placeholder="Email" v-model="model.email" label="Email"  ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" v-if="workingPointType == 'WORKING_POINT'">
                <v-text-field type="text" placeholder="Telefon" v-model="model.phoneNumber" label="Telefon"  ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense class="pt-5" v-if="workingPointType == 'WORKING_POINT'">
              <v-col cols="12">
                <div><span class="font-weight-bold">Autorizatie de mediu</span> (doar pentru punctele de lucru unde se aplica):</div>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field type="text" placeholder="Numar autorizatie mediu" v-model="model.noAuth"
                  :label="getAuthLabel()" :rules="getAuthRules()" clearable />
              </v-col>
              <v-col cols="12" md="4">
                <v-dialog max-width="300px" persistent v-model="modal1">
                  <template v-slot:activator="{ on: { click } }">
                    <v-text-field v-on:click="click" v-model="model.startDateAuth" :label="getStartAuthLabel()"
                    :rules="getStartAuthRules()" clearable></v-text-field>
                  </template>
                  <v-date-picker v-model="model.startDateAuth" scrollable actions>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="modal1 = false">Cancel</v-btn>
                      <v-btn color="primary" @click="modal1 = false">OK</v-btn>
                    </v-card-actions>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" md="4">
                <v-dialog max-width="300px" persistent v-model="modal2">
                  <template v-slot:activator="{ on: { click } }">
                    <v-text-field v-on:click="click" v-model="model.endDateAuth" :label="getEndAuthLabel()"
                    :rules="getEndAuthRules()" clearable></v-text-field>
                  </template>
                  <v-date-picker v-model="model.endDateAuth" scrollable actions>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="modal2 = false">Cancel</v-btn>
                      <v-btn color="primary" @click="modal2 = false">OK</v-btn>
                    </v-card-actions>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="cardActions">
          <v-btn type="button" class="mainButtonYes" text @click="save()">Adauga</v-btn>
          <v-btn type="button" class="mainButtonNo" color="red" @click="close()">Renunta</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
import moment from "moment-timezone";
import axiosInstance from "../../../store/axiosWrapper";
export default {
  props: [
    "closeDialog",
    "companies",
    "companyOnly",
    "createCb"
  ],
  data: () => ({
    dialog: true,
    inputRules: [v => !!v || "Campul este obligatoriu"],
    model: {},
    company: null,
    workingPointTypes: [{
      text: "Entitate parinte",
      value: "COMPANY"
    },
    {
      text: "Entitate copil",
      value: "WORKING_POINT"
    }
    ],
    workingPointType: "WORKING_POINT",
    activityTypes: [
      'HoReCa',
      'Beauty',
      'Birou',
      'Medical',
      'Magazin',
      'Depozit',
      'Productie',
      'Altele',
    ],
    modal1: false,
    modal2: false,
    formLabels: {
      name: 'Nume punct de lucru *',
      city: 'Oras punct de lucru *',
      county: 'Judet punct de lucru *',
      address: 'Adresa punct de lucru *',
    },
    formLabelsCompany: {
      name: 'Nume companie *',
      city: 'Oras sediu social *',
      county: 'Judet sediu social *',
      address: 'Adresa sediu social *',
    },
    waitForAnaf: false
  }),
  watch: {
    company() {
      // if (this.company) {
      //   this.model.vatId = this.company.vatId
      // } else {
      //   this.model.vatId = null
      // }
      this.model.vatId = null
      this.model.company = this.company._id
    }
  },
  methods: {
    close() {
      this.closeDialog()
    },
    getFieldLabel(field) {
      if(this.workingPointType == 'WORKING_POINT') {
        return this.formLabels[field]
      } else {
        return this.formLabelsCompany[field]
      }
    },
    getCUIRUles() {
      if (this.workingPointType == 'COMPANY') {
        return [
          v => !!v || `Completeaza codul fiscal`,
          v => v && v.length <= 100 || `Codul fiscal trebuie sa contina cel mult 50 de caractere`
        ]
      }
      return []
    },
    getCUILabel() {
      if (this.workingPointType == 'COMPANY') {
        return 'Cod Fiscal *'
      }
      return 'Cod Fiscal'
    },
    getAuthRules() {
      if(this.model.endDateAuth || this.model.startDateAuth) {
        return [
          v => !!v || `Completeaza numar autorizatie de mediu`,
          v => v && v.length <= 10 || `Numarul autorizatiei de mediu trebuie sa contina cel mult 10 de caractere`
        ]
      } else {
        return [
          v => v && v.length <= 10 || !v || `Numarul autorizatiei de mediu trebuie sa contina cel mult 10 de caractere`
        ]
      }
    },
    getAuthLabel() {
      if (this.model.endDateAuth || this.model.startDateAuth) {
        return 'Numar autorizatie de mediu *'
      }
      return 'Numar autorizatie de mediu'
    },
    getStartAuthRules() {
      if(this.model.endDateAuth || this.model.noAuth) {
        return [
          v => !!v || `Completeaza data start autorizatie de mediu`
        ]
      }
      return []
    },
    getStartAuthLabel() {
      if (this.model.endDateAuth || this.model.noAuth) {
        return 'Data start autorizatie de mediu *'
      }
      return 'Data start autorizatie de mediu'
    },
    getEndAuthRules() {
      if(this.model.noAuth || this.model.startDateAuth) {
        return [
          v => !!v || `Completeaza data stop autorizatie de mediu`
        ]
      }
      return []
    },
    getEndAuthLabel() {
      if (this.model.noAuth || this.model.startDateAuth) {
        return 'Data stop autorizatie de mediu *'
      }
      return 'Data stop autorizatie de mediu'
    },
    async getPartnerDataFromAnaf() {
      if(this.model.vatId) {
        if(!this.model.name && !this.model.address && !this.model.regCom && !this.model.city && !this.model.county) {
          this.waitForAnaf = true;
          let vatId = this.model.vatId.replace(/[ROro ]/g, '').trim()
          const currentDate = moment().tz('Europe/Bucharest').format('YYYY-MM-DD')
          try {
            const res = await axiosInstance.get(
              `${process.env.VUE_APP_API_URL}/partners/dataFromAnaf/${vatId}/${currentDate}`,
              {
                withCredentials: true,
              },
            );
            if(res && res.data && res.data.name) {
              this.$set(this.model, 'name', res.data.name);
              if(res.data.regCom) {
                this.$set(this.model, 'regCom', res.data.regCom);
              }
              this.$set(this.model, 'address', res.data.address);
              this.$set(this.model, 'city', res.data.city);
              this.$set(this.model, 'county', res.data.county);
            }
            this.waitForAnaf = false;
          } catch(e) {
            // console.log(e)
            this.waitForAnaf = false;
            return
          }
        }
      }
    },
    changeWorkingPointType() {
      if (this.workingPointType == 'COMPANY') {
        this.model.vatId = null
        this.model.company = null
      } else {
        this.model.company = this.companies ? this.companies[0] : null
        // this.model.vatId = this.model.company ? this.model.company.vatId : null
      }
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.model._id) {
          // this.$store.dispatch('updateIngredient', {data: this.model, workingPointId: this.workingPointId, cb: () => {if(this.callback) {this.callback()}}});
        } else {
          if (this.workingPointType == 'WORKING_POINT') {
            this.$store.dispatch('createWorkingPoint', {
              data: this.model,
              cb: () => {
                this.$store.dispatch('fetchWorkingPoints');
                if (this.createCb) {
                  this.createCb()
                }
              }
            });
          } else {
            this.$store.dispatch('createCompany', {
              data: this.model,
              cb: () => {
                this.$store.dispatch('fetchCompanies');
                if (this.createCb && this.companyOnly) {
                  this.createCb()
                }
              }
            });
          }
        }
        this.close()
      }
    },
  },
  computed: {
    isAdmin() {
      return this.$store.state.auth.isAdmin
    }
  },
  created() {
    if (this.companyOnly) {
      this.workingPointType = 'COMPANY'
    } else {
      this.company = this.companies ? this.companies[0] : null
      this.model.company = this.companies ? this.companies[0] : null
      // this.model.vatId = this.model.company ? this.model.company.vatId : null
    }
  }
};
</script>
