var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-row',{staticClass:"mt-2 mr-2"},[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.closeDialog()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-title',{staticClass:"mt-3 flex justify-center"},[_c('div',{staticStyle:{"margin-right":"10px"}},[_c('v-img',{staticClass:"step-icon",attrs:{"width":40,"src":require('@/assets/workingPoint.svg')}})],1),_c('span',{staticClass:"headline font-weight-bold"},[_vm._v(" Pasul 1 din 3. Adauga punctul de lucru ")])]),_c('v-card-text',[_c('v-form',{ref:"form",staticClass:"w-full pa-0 pa-md-5 mt-5 mt-md-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Trebuie sa adaugati detaliile punctului de lucru unde va desfasurati activitatea. ")]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"label":"Alege organizatia *","items":_vm.companies,"color":"blue-grey-lighten-2","item-text":"companyName","item-value":"_id","rules":[function (v) { return !!v || "Alege organizatia *"; }],"item":"","outlined":"","return-object":""},model:{value:(_vm.model.company),callback:function ($$v) {_vm.$set(_vm.model, "company", $$v)},expression:"model.company"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{ref:"focusMe",attrs:{"type":"text","placeholder":"Nume punct de lucru *","required":"","label":"Nume punct de lucru *","rules":[
              function (v) { return !!v || "Completeaza numele punctului de lucru"; },
              function (v) { return v && v.length <= 50 || "Numele trebuie sa contina cel mult 50 de caractere"; }
            ],"outlined":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"medium":""}},on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_vm._v(" Trebuie sa completati numele punctului de lucru la care va desfasurati activitatea. "),_c('br'),_vm._v(" Acesta nu este unul legal, poate fi Magazin, Birou, numele magazinului, numele restaurantului, numele companiei daca aveti un singur punct de lucru etc ")])]},proxy:true}]),model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"type":"text","placeholder":"Oras punct de lucru *","required":"","label":"Oras punct de lucru *","rules":[
              function (v) { return !!v || "Completeaza oras punct de lucru"; },
              function (v) { return v && v.length <= 100 || "Orasul trebuie sa contina cel mult 50 de caractere"; }
            ],"outlined":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"medium":""}},on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_vm._v(" Trebuie sa completati orasul in care este situat punctul de lucru asa cum este inregistrat la Registrul Comertului ")])]},proxy:true}]),model:{value:(_vm.model.city),callback:function ($$v) {_vm.$set(_vm.model, "city", $$v)},expression:"model.city"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"type":"text","placeholder":"Judet punct de lucru *","required":"","label":"Judet punct de lucru *","rules":[
              function (v) { return !!v || "Completeaza judet punct de lucru"; },
              function (v) { return v && v.length <= 100 || "Judetul trebuie sa contina cel mult 50 de caractere"; }
            ],"outlined":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"medium":""}},on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_vm._v(" Trebuie sa completati judetul in care este situat punctul de lucru asa cum este inregistrat la Registrul Comertului ")])]},proxy:true}]),model:{value:(_vm.model.county),callback:function ($$v) {_vm.$set(_vm.model, "county", $$v)},expression:"model.county"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"type":"text","placeholder":"Adresa punct de lucru *","required":"","label":"Adresa punct de lucru *","rules":[
              function (v) { return !!v || "Completeaza adresa punct de lucru *"; },
              function (v) { return v && v.length <= 100 || "Adresa trebuie sa contina cel mult 50 de caractere"; }
            ],"outlined":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"medium":""}},on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_vm._v(" Trebuie sa completati adresa la care este situat punctul de lucru asa cum este inregistrat la Registrul Comertului ")])]},proxy:true}]),model:{value:(_vm.model.address),callback:function ($$v) {_vm.$set(_vm.model, "address", $$v)},expression:"model.address"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"label":"Alege tipul de activitate","items":_vm.activityTypes,"color":"blue-grey-lighten-2","outlined":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"medium":""}},on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_vm._v(" Alegeti tipul de activitate ce este desfasurata la punctul de lucru ")])]},proxy:true}]),model:{value:(_vm.model.activityType),callback:function ($$v) {_vm.$set(_vm.model, "activityType", $$v)},expression:"model.activityType"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"type":"text","placeholder":"Cod Fiscal","required":"","label":"Cod Fiscal","outlined":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"medium":""}},on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_vm._v(" Se completeaza numai daca punctul de lucru are Cod fiscal diferit de cel al companiei. ")])]},proxy:true}]),model:{value:(_vm.model.vatId),callback:function ($$v) {_vm.$set(_vm.model, "vatId", $$v)},expression:"model.vatId"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"type":"text","placeholder":"Nr Registrul comertului","label":"Nr Registrul comertului","outlined":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"medium":""}},on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_vm._v(" Se completeaza numai daca punctul de lucru are Numar Registrul Comertului diferit de cel al companiei. ")])]},proxy:true}]),model:{value:(_vm.model.regCom),callback:function ($$v) {_vm.$set(_vm.model, "regCom", $$v)},expression:"model.regCom"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"type":"text","placeholder":"Persoana de contact","label":"Persoana de contact","outlined":""},model:{value:(_vm.model.contactPerson),callback:function ($$v) {_vm.$set(_vm.model, "contactPerson", $$v)},expression:"model.contactPerson"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"type":"text","placeholder":"Email","label":"Email","outlined":""},model:{value:(_vm.model.email),callback:function ($$v) {_vm.$set(_vm.model, "email", $$v)},expression:"model.email"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"type":"text","placeholder":"Telefon","label":"Telefon","outlined":""},model:{value:(_vm.model.phoneNumber),callback:function ($$v) {_vm.$set(_vm.model, "phoneNumber", $$v)},expression:"model.phoneNumber"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Autorizatie de mediu")]),_vm._v(" (doar pentru punctele de lucru unde se aplica):")])]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"type":"text","placeholder":"Numar autorizatie mediu","label":_vm.getAuthLabel(),"rules":_vm.getAuthRules(),"clearable":"","outlined":""},model:{value:(_vm.model.noAuth),callback:function ($$v) {_vm.$set(_vm.model, "noAuth", $$v)},expression:"model.noAuth"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-dialog',{attrs:{"max-width":"300px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var click = ref.on.click;
return [_c('v-text-field',{attrs:{"label":_vm.getStartAuthLabel(),"rules":_vm.getStartAuthRules(),"clearable":"","outlined":""},on:{"click":click},model:{value:(_vm.model.startDateAuth),callback:function ($$v) {_vm.$set(_vm.model, "startDateAuth", $$v)},expression:"model.startDateAuth"}})]}}]),model:{value:(_vm.modal1),callback:function ($$v) {_vm.modal1=$$v},expression:"modal1"}},[_c('v-date-picker',{attrs:{"scrollable":"","actions":""},model:{value:(_vm.model.startDateAuth),callback:function ($$v) {_vm.$set(_vm.model, "startDateAuth", $$v)},expression:"model.startDateAuth"}},[_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.modal1 = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.modal1 = false}}},[_vm._v("OK")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-dialog',{attrs:{"max-width":"300px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var click = ref.on.click;
return [_c('v-text-field',{attrs:{"label":_vm.getEndAuthLabel(),"rules":_vm.getEndAuthRules(),"clearable":"","outlined":""},on:{"click":click},model:{value:(_vm.model.endDateAuth),callback:function ($$v) {_vm.$set(_vm.model, "endDateAuth", $$v)},expression:"model.endDateAuth"}})]}}]),model:{value:(_vm.modal2),callback:function ($$v) {_vm.modal2=$$v},expression:"modal2"}},[_c('v-date-picker',{attrs:{"scrollable":"","actions":""},model:{value:(_vm.model.endDateAuth),callback:function ($$v) {_vm.$set(_vm.model, "endDateAuth", $$v)},expression:"model.endDateAuth"}},[_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.modal2 = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.modal2 = false}}},[_vm._v("OK")])],1)],1)],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"cardActions"},[_c('v-spacer',{staticStyle:{"max-width":"80%"}}),_c('v-btn',{staticClass:"mainButtonYes",staticStyle:{"width":"200px"},attrs:{"type":"button","text":""},on:{"click":function($event){return _vm.save()}}},[_vm._v("Continua")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }