<template>
  <div style="margin: 10px">
    <section-headline> Panou de bord admin </section-headline>
    <div class="flex">
      <v-btn @click="exportUsers()" class="text-gray-500 primary">
        <span>Exporta</span>
      </v-btn>
      <v-progress-circular
        indeterminate
        color="primary"
        v-if="loading"
      ></v-progress-circular>
    </div>
    <v-data-table
      class="adminUsersTable"
      :items="users"
      :headers="userHeaders"
      :search="search"
      :options.sync="options"
      :server-items-length="totalUsers"
      :footer-props="{
        itemsPerPageOptions: [25, 50, 100],
      }"
    >
      <template v-slot:top>
        <div class="pb-6">
          <v-row>
            <v-col cols="12" md="4" lg="3">
              <v-text-field
                v-model="search"
                label="Search"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" lg="3">
              <v-combobox
                clearable
                label="Tip abonament"
                v-model="paymentFilter"
                density="compact"
                hide-details
                underlined
                :items="paymentTypes"
                color="primary"
                return-object
              >
              </v-combobox>
            </v-col>
            <v-col cols="12" md="4" lg="3">
              <v-combobox
                clearable
                label="Status abonament"
                v-model="paymentStatusFilter"
                density="compact"
                underlined
                hide-details
                :items="paymentStatuses"
                color="primary"
                return-object
              >
              </v-combobox>
            </v-col>
            <v-col cols="12" md="4" lg="3">
              <v-combobox
                clearable
                label="Metoda de plata"
                v-model="paymentMethodFilter"
                density="compact"
                underlined
                hide-details
                :items="paymentMethods"
                color="primary"
                return-object
              >
              </v-combobox>
            </v-col>
            <v-col cols="12" md="4" lg="3">
              <v-menu offset-y :close-on-content-click="false" v-model="createdFilter.show">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="createdFilter.filter"
                    label="Data creare"
                    clearable
                    hide-details
                    underlined
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="clearDateField(createdFilter)"
                  ></v-text-field>
                </template>
                <v-card class="d-flex flex-column align-center bordered w-full">
                  <div class="d-flex justify-space-around w-full">
                    <div style="width: 45%;">
                      <v-text-field
                        v-model="createdFilter.date1"
                        type="date"
                        label="Alege data start"
                      />
                    </div>
                    <div style="width: 45%;">
                      <v-text-field
                        v-model="createdFilter.date2"
                        type="date"
                        label="Alege data stop"
                      />
                    </div>
                  </div>
                  <v-btn class="text-secondary" @click="formatDateFilter(createdFilter)">
                    OK
                  </v-btn>
                </v-card>
              </v-menu>
            </v-col>
            <v-col cols="12" md="4" lg="3">
              <v-menu offset-y :close-on-content-click="false" v-model="expiredFilter.show">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    underlined
                    hide-details
                    color="primary"
                    v-model="expiredFilter.filter"
                    label="Data expirare"
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="clearDateField(expiredFilter)"
                  ></v-text-field>
                </template>
                <v-card class="d-flex flex-column align-center bordered">
                  <div class="d-flex justify-space-around w-full">
                    <div style="width: 45%;">
                      <v-text-field
                        v-model="expiredFilter.date1"
                        type="date"
                        label="Alege data start"
                      />
                    </div>
                    <div style="width: 45%;">
                      <v-text-field
                        v-model="expiredFilter.date2"
                        type="date"
                        label="Alege data stop"
                      />
                    </div>
                  </div>
                  <v-btn class="text-secondary" @click="formatDateFilter(expiredFilter)">
                    OK
                  </v-btn>
                </v-card>
              </v-menu>
            </v-col>
            <v-col cols="12" md="4" lg="3">
              <v-combobox
                clearable
                label="Ultimul status"
                v-model="statusFilter"
                density="compact"
                hide-details
                underlined
                item-text="status"
                item-value="status"
                :items="userStatuses"
                color="primary"
                :return-object="false"
              >
              </v-combobox>
            </v-col>
            <v-col cols="12" md="4" lg="3">
              <v-menu
                offset-y
                :close-on-content-click="false"
                v-model="statusDateFilter.show"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    underlined
                    hide-details
                    color="primary"
                    v-model="statusDateFilter.filter"
                    label="Data ultimului status"
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="clearDateField(statusDateFilter)"
                  ></v-text-field>
                </template>
                <v-card class="d-flex flex-column align-center bordered">
                  <div class="d-flex justify-space-around w-full">
                    <div style="width: 45%;">
                      <v-text-field
                        v-model="statusDateFilter.date1"
                        type="date"
                        label="Alege data start"
                      />
                    </div>
                    <div style="width: 45%;">
                      <v-text-field
                        v-model="statusDateFilter.date2"
                        type="date"
                        label="Alege data stop"
                      />
                    </div>
                  </div>
                  <v-btn class="text-secondary" @click="formatDateFilter(statusDateFilter)">
                    OK
                  </v-btn>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>
        </div>
        <!-- <div class="flex topSection">
          <v-text-field
            class="searchWidth"
            v-model="search"
            label="Search"
            hide-details
          ></v-text-field>
          <v-combobox
            clearable
            label="Tip abonament"
            v-model="paymentFilter"
            class="left filterWidth paddedLeft"
            density="compact"
            hide-details
            underlined
            :items="paymentTypes"
            color="primary"
            return-object
          >
          </v-combobox>
          <v-combobox
            clearable
            label="Status abonament"
            v-model="paymentStatusFilter"
            class="left filterWidth paddedLeft"
            density="compact"
            underlined
            hide-details
            :items="paymentStatuses"
            color="primary"
            return-object
          >
          </v-combobox>

          <v-combobox
            clearable
            label="Metoda de plata"
            v-model="paymentMethodFilter"
            class="left filterWidth paddedLeft"
            density="compact"
            underlined
            hide-details
            :items="paymentMethods"
            color="primary"
            return-object
          >
          </v-combobox>

          <v-menu offset-y :close-on-content-click="false" v-model="createdFilter.show">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="createdFilter.filter"
                label="Data creare"
                clearable
                class="left paddedLeft"
                style="max-width: 560px"
                hide-details
                underlined
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click:clear="clearDateField(createdFilter)"
              ></v-text-field>
            </template>
            <v-card class="d-flex flex-column align-center bordered w-full">
              <div class="d-flex justify-space-around gap-4 w-full">
                <div style="width: 40%;">
                  <v-text-field
                    v-model="createdFilter.date1"
                    style="width: 100%;"
                    type="date"
                    label="Alege data start"
                  />
                </div>
                <div style="width: 40%;">
                  <v-text-field
                    v-model="createdFilter.date2"
                    type="date"
                    label="Alege data stop"
                  />
                </div>
              </div>
              <v-btn class="text-secondary" @click="formatDateFilter(createdFilter)">
                OK
              </v-btn>
            </v-card>
          </v-menu>
          <v-menu offset-y :close-on-content-click="false" v-model="expiredFilter.show">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="left paddedLeft"
                style="max-width: 560px"
                underlined
                hide-details
                color="primary"
                v-model="expiredFilter.filter"
                label="Data expirare"
                clearable
                v-bind="attrs"
                v-on="on"
                @click:clear="clearDateField(expiredFilter)"
              ></v-text-field>
            </template>
            <v-card class="d-flex flex-column align-center bordered">
              <div class="d-flex justify-center">
                <v-text-field
                  v-model="expiredFilter.date1"
                  class="mr-5"
                  type="date"
                  label="Alege data start"
                />
                <v-text-field
                  v-model="expiredFilter.date2"
                  type="date"
                  label="Alege data stop"
                />
              </div>
              <v-btn class="text-secondary" @click="formatDateFilter(expiredFilter)">
                OK
              </v-btn>
            </v-card>
          </v-menu>
        </div>
        <div class="flex topSection justify-start">
          <v-combobox
            clearable
            label="Ultimul status"
            v-model="statusFilter"
            class="left"
            style="max-width: 250px"
            density="compact"
            hide-details
            underlined
            item-text="status"
            item-value="status"
            :items="userStatuses"
            color="primary"
            :return-object="false"
          >
          </v-combobox>

          <v-menu
            offset-y
            :close-on-content-click="false"
            v-model="statusDateFilter.show"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="left paddedLeft"
                style="max-width: 250px"
                underlined
                hide-details
                color="primary"
                v-model="statusDateFilter.filter"
                label="Data ultimului status"
                clearable
                v-bind="attrs"
                v-on="on"
                @click:clear="clearDateField(statusDateFilter)"
              ></v-text-field>
            </template>
            <v-card class="d-flex flex-column align-center bordered">
              <div class="d-flex justify-center">
                <v-text-field
                  v-model="statusDateFilter.date1"
                  class="mr-5"
                  type="date"
                  label="Alege data start"
                />
                <v-text-field
                  v-model="statusDateFilter.date2"
                  type="date"
                  label="Alege data stop"
                />
              </div>
              <v-btn class="text-secondary" @click="formatDateFilter(statusDateFilter)">
                OK
              </v-btn>
            </v-card>
          </v-menu>
        </div> -->
      </template>
      <!-- <template v-slot:[`item.mainCompany`]="props">
        <v-btn @click="impersonateUser(props.item)" class="pl-1 pl-sm-0 btnImpersonate">
          <v-icon color="secondary pr-1" class="pr-1 pr-sm-0">mdi-drama-masks</v-icon>
          <span class="txtUserName">{{ props.item.mainCompany }}</span>
          <v-tooltip location="bottom" activator="parent">
            <span class="text-primary">IMPERSONARE</span>
          </v-tooltip>
        </v-btn>

      
      </template> -->
      <template v-slot:[`item.createdAt`]="props">
        {{ parseDate(props.item.createdAt) }}
      </template>

      <template v-slot:[`item.paidByOP`]="props">
        <div v-if="props.item.lastPayment.paidByOP" color="success">OP</div>
        <div v-else color="red">Card</div>
      </template>

      <template v-slot:[`item.phoneNumber`]="props">
        <div class="flex align-center">
          {{ props.item.phoneNumber }}
          <v-btn text @click="openWhatsapp(props.item.phoneNumber)">
            <v-icon color="green"> mdi-whatsapp </v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:[`item.endDate`]="props">
        {{ parseDate(props.item.lastPayment.endDate) }}
      </template>

      <template v-slot:[`item.lastLog`]="props">
        <div v-if="props.item.lastLog" class="flex flex-column">
          <b>{{ props.item.lastLog.status }}</b>
          <i
            style="
              max-height: 200px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: pre;
              max-width: 200px;
            "
            >{{ props.item.lastLog.comment }}</i
          >
          <div>{{ parseDate(props.item.lastLog.createdAt) }}</div>
        </div>
      </template>
      <template v-slot:[`item.paymentStatus`]="props">
        {{ props.item.paymentStatus == "DISABLED" ? "INACTIV" : "ACTIV" }}
      </template>
      <template v-slot:[`item.actions`]="props">
        <div class="flex">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="impersonateUser(props.item)"
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="secondary">mdi-drama-masks</v-icon>
              </v-btn>
            </template>
            <span>Impersonare</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="openLogDialog(props.item)"
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-clipboard-plus-outline</v-icon>
              </v-btn>
            </template>
            <span>Adauga observatie</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="openHistoryDialog(props.item)"
                icon
                color="accent"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-clipboard-text-clock-outline</v-icon>
              </v-btn>
            </template>
            <span>Istoric observatii</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <PaymentsDialog
      v-if="paymentDialog"
      :user="editedUser"
      :closeDialog="closePayments"
    />
    <AddLogDialog
      v-if="logDialog"
      :user="editedUser"
      :getData="getData"
      :closeDialog="closeLogDialog"
    >
    </AddLogDialog>
    <HistoryDialog
      v-if="historyDialog"
      :user="editedUser"
      :closeDialog="closeHistoryDialog"
    >
    </HistoryDialog>
  </div>
</template>
<script>
import moment from "moment-timezone";
import AddButton from "../components/Buttons/AddButton.vue";
import PaymentsDialog from "../components/Modals/PaymentsDialog.vue";
import SectionHeadline from "../components/Typography/SectionHeadline.vue";
import AddLogDialog from "../components/Modals/AddLogDialog.vue";
import HistoryDialog from "../components/Modals/HistoryDialog.vue";
export default {
  components: {
    SectionHeadline,
    AddButton,
    PaymentsDialog,
    AddLogDialog,
    HistoryDialog,
  },
  props: ["impersonateUser"],
  data() {
    return {
      search: "",
      timeout: null, // Add this line
      loading: false,
      options: {},
      paymentFilter: null,
      statusFilter: null,
      paymentStatusFilter: null,
      paymentMethodFilter: null,
      createdFilter: {
        show: false,
        date1: null,
        date2: null,
        filter: null,
      },
      statusDateFilter: {
        show: false,
        date1: null,
        date2: null,
        filter: null,
      },
      expiredFilter: {
        show: false,
        date1: null,
        date2: null,
        filter: null,
      },
      paymentTypes: ["ADVANCED", "ADVANCED YEARLY", "FREE"],
      paymentStatuses: ["ACTIV", "INACTIV"],
      paymentMethods: ["OP", "CARD"],
      paymentDialog: false,
      logDialog: false,
      historyDialog: false,
      userHeaders: [
        {
          text: "",
          value: "actions",
          sortable: false,
        },
        {
          text: "Nume",
          value: "mainCompany",
          width: "400px",
          align: "start",
          sortable: false,
        },
        {
          text: "Nume client",
          value: "name",
          sortable: false,
        },
        {
          text: "Email",
          value: "email",
          sortable: false,
        },
        {
          text: "Telefon",
          value: "phoneNumber",
          sortable: false,
        },
        {
          text: "Ultima observatie",
          value: "lastLog",
          sortable: false,
        },
        {
          text: "CUI companie platitoare",
          value: "mainVatId",
          sortable: false,
        },
        {
          text: "Numar puncte lucru",
          value: "workingPointsNo",
          // sortable: false,
        },
        {
          text: "Puncte lucru achizitionate",
          value: "allowedWorkingPoints",
        },
        /**
         * nr de transporturi adaugate
  Nr de pct de lucru achizitionate
         */
        {
          text: "Transporturi adaugate",
          value: "transports",
          // sortable: false,
        },
        {
          text: "Tip Abonament",
          value: "lastPayment.productId",
          sortable: false,
        },
        {
          text: "Metoda de plata",
          value: "paidByOP",
          sortable: false,
        },
        {
          text: "Status Abonament",
          value: "paymentStatus",
          sortable: false,
        },
        {
          text: "Data creare",
          value: "createdAt",
          // sortable: false,
        },
        {
          text: "Data expirare",
          value: "endDate",
          sortable: false,
        },

        {
          text: "Rol",
          value: "role",
          sortable: false,
        },
      ],
    };
  },
  watch: {
    options() {
      this.getData();
    },
    paymentFilter() {
      this.getData();
    },
    statusFilter() {
      this.getData();
    },
    paymentStatusFilter() {
      this.getData();
    },
    paymentMethodFilter() {
      this.getData();
    },
    search(newVal) {
      clearTimeout(this.timeout); // Clear the previous timeout
      this.timeout = setTimeout(() => {
        this.getData();
      }, 500);
    },
  },
  computed: {
    users() {
      // console.log(this.$store.state.admin.users)
      return this.$store.state.admin.users;
    },
    totalUsers() {
      return this.$store.state.admin.totalUsers;
    },
    userStatuses() {
      return this.$store.state.userStatuses.userStatuses;
    },
  },
  methods: {
    openWhatsapp(phone) {
      let formattedPhone = phone.replace(/\D/g, "");

      // Check if the phone number starts with '40' (Romanian country code)
      if (!formattedPhone.startsWith("40")) {
        // If it doesn't, prepend '40' to the phone number
        formattedPhone = "40" + formattedPhone;
      }

      // Construct the WhatsApp URL
      const whatsappUrl = `https://wa.me/${formattedPhone}`;

      // Open WhatsApp in a new tab with the correct number
      window.open(whatsappUrl, "_blank");
    },
    showPayments(item) {
      this.paymentDialog = true;
      this.editedUser = item;
    },
    openLogDialog(item) {
      this.logDialog = true;
      this.editedUser = item;
    },
    openHistoryDialog(item) {
      this.historyDialog = true;
      this.editedUser = item;
    },
    closeLogDialog() {
      this.logDialog = false;
      this.editedUser = null;
    },
    closeHistoryDialog() {
      this.historyDialog = false;
      this.editedUser = null;
    },
    closePayments() {
      (this.paymentDialog = false), (this.editedUser = null);
    },
    clearDateField(filterItem) {
      filterItem.date1 = null;
      filterItem.date2 = null;
      this.getData();
    },
    formatDateFilter(filterItem) {
      let filterValue = null;
      if (!filterItem.date1 || !filterItem.date2) {
        return;
      }
      filterValue = `${filterItem.date1} - ${filterItem.date2}`;
      filterItem.filter = filterValue;
      filterItem.show = false;
      this.getData();
    },
    getFilter() {
      let filter = {};
      if (this.createdFilter && this.createdFilter.date1 && this.createdFilter.date2) {
        filter.createdAt = {
          date1: this.createdFilter.date1,
          date2: this.createdFilter.date2,
        };
      }
      if (
        this.statusDateFilter &&
        this.statusDateFilter.date1 &&
        this.statusDateFilter.date2
      ) {
        filter.lastStatusDate = {
          date1: this.statusDateFilter.date1,
          date2: this.statusDateFilter.date2,
        };
      }
      if (this.expiredFilter && this.expiredFilter.date1 && this.expiredFilter.date2) {
        filter.endDate = {
          date1: this.expiredFilter.date1,
          date2: this.expiredFilter.date2,
        };
      }
      return filter;
    },
    parseDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    exportUsers() {
      let filter = this.getFilter();
      this.loading = true;
      this.$store.dispatch("exportUsers", {
        search: this.search,
        paymentFilter: this.paymentFilter,
        paymentStatusFilter: this.paymentStatusFilter,
        paymentMethodFilter: this.paymentMethodFilter,
        statusFilter: this.statusFilter,
        filter: filter,
        sort: this.options.sortBy[0],
        sortType: this.options.sortDesc[0] ? 'DESC' : 'ASC',
        cb: (response) => {
          let fileName = "Utilizatori RD.xlsx";
          this.loading = false;
          let fileUrl = window.URL.createObjectURL(response.data);
          let fileLink = document.createElement("a");
          fileLink.href = fileUrl;
          fileLink.setAttribute("download", fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
        },
      });
    },
    getData() {
      let filter = this.getFilter();
      this.loading = true;
      this.$store.dispatch("fetchUsers", {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        search: this.search,
        paymentFilter: this.paymentFilter,
        paymentStatusFilter: this.paymentStatusFilter,
        paymentMethodFilter: this.paymentMethodFilter,
        statusFilter: this.statusFilter,
        filter: filter,
        sort: this.options.sortBy[0],
        sortType: this.options.sortDesc[0] ? 'DESC' : 'ASC',
        cb: () => {
          this.loading = false;
        },
      });
    },
  },
  created() {
    this.$store.dispatch("fetchUserStatuses", {});
    // this.getData()
  },
};
</script>
<style scoped>
.filterWidth {
  width: 20%;
}
.searchWidth {
  width: 25%;
}
.paddedLeft {
  padding-left: 10px;
}
</style>
